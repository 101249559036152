import { getDatabase, push, ref, set, remove } from 'firebase/database';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';

export interface Prize {
  id?: string;
  imageURL: string;
  prizeName: string;
  quota: number;
}

const options = {
  keyField: 'id',
  transform: ({ ...val }) =>
    ({
      ...val,
    } as Prize),
};

const database = getDatabase();
const prizesRef = ref(database, 'prizes');
const prizeRef = (id: string) => ref(database, `prizes/${id}`);

export const usePrizes = () => useListVals<Prize>(prizesRef, options);

export const usePrize = (id: string) =>
  useObjectVal<Prize>(prizeRef(id), options);

export const addPrize = (prize: Prize) => {
  push(prizesRef, prize);
};

export const updatePrize = (prize: Prize) => {
  if (!prize.id) return;
  set(prizeRef(prize.id), prize);
};

export const deletePrize = (prizeId: string) => {
  remove(prizeRef(prizeId));
};
