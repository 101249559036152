import React, { FormEvent, useState } from 'react';
import NavBar from '../components/NavBar';
import PrizeCard from '../components/PrizeCard';
import { useCurrentPrize } from '../hooks/useCurrentPrize';
import { addPrize, usePrizes } from '../hooks/usePrize';

const Settings = () => {
  const [imageURL, setImageURL] = useState('');
  const [prizeName, setprizeName] = useState('');
  const [quota, setQuota] = useState(0);

  const [prizesList] = usePrizes();
  const [currentPrize] = useCurrentPrize();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const newPrize = { imageURL, prizeName, quota };
    await addPrize(newPrize);
    setImageURL('');
    setprizeName('');
    setQuota(0);
  };

  return (
    <>
      <NavBar />
      <div className="py-6 px-4 lg:px-12 2xl:px-40 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
        <div className="card bg-white">
          {imageURL && (
            <img src={imageURL} alt="demoImage" className="w-full" />
          )}
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <input
                id="imageURL"
                type="text"
                value={imageURL}
                onChange={(e) => setImageURL(e.target.value)}
                placeholder="imageURL"
                className="form-control my-2"
              />
              <input
                id="prizeName"
                type="text"
                value={prizeName}
                onChange={(e) => setprizeName(e.target.value)}
                placeholder="prizeName"
                className="form-control my-2"
              />
              <input
                id="quota"
                type="number"
                value={quota}
                onChange={(e) => setQuota(parseInt(e.target.value))}
                placeholder="quota"
                className="form-control my-2"
              />
              <button className="button">Submit</button>
            </form>
          </div>
        </div>
        {prizesList &&
          prizesList.map((prize) => (
            <PrizeCard
              key={prize.id}
              {...prize}
              isCurrent={currentPrize?.id === prize.id}
            />
          ))}
      </div>
    </>
  );
};

export default Settings;
