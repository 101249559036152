import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import firebaseConfig from '../config/firebase';

export const firebaseApp = initializeApp(firebaseConfig);

export const database = getDatabase(firebaseApp);

export const auth = getAuth(firebaseApp);
