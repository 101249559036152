import { TwitterTweetEmbed } from 'react-twitter-embed';
import NavBar from '../components/NavBar';

const DashBoard = () => {
  return (
    <>
      <NavBar />
      <div className="px-2 grid grid-cols-4 gap-2">
        <TwitterTweetEmbed tweetId={'1561294539398209537'} />
        <TwitterTweetEmbed tweetId={'1561278433052745728'} />
        <TwitterTweetEmbed tweetId={'1563075473256488961'} />
      </div>
    </>
  );
};

export default DashBoard;
