import AuthProvider from './context/AuthProvider';
import AppRouter from './routes/AppRouter';

const App = () => {
  return (
    <AuthProvider>
      <div className=" bg-gray-100 min-h-screen">
        <AppRouter />
      </div>
    </AuthProvider>
  );
};

export default App;
