import { User } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { AuthContext } from '../hooks/useAuth';
import { auth } from '../utils/firebaseSDK';

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoading(false);
      setUser(user);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ user, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
