import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

const NavBar = () => {
  const { signOut } = useFirebaseAuth();
  const handleLogOut = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    signOut();
  };
  return (
    <nav className="flex items-center justify-between flex-wrap bg-zinc-800 p-4 text-white">
      <div className="flex-grow flex space-x-4 items-center">
        <div>
          <Link to="/">
            <span className="font-semibold text-xl tracking-tight">
              Lottery
            </span>
          </Link>
        </div>
        <div>
          <ul className="flex space-x-4">
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) => (isActive ? ' underline' : '')}
              >
                抽獎後台
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings"
                className={({ isActive }) => (isActive ? ' underline' : '')}
              >
                設定獎項
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <a href="##" className=" " onClick={handleLogOut}>
          登出
        </a>
      </div>
    </nav>
  );
};

export default NavBar;
