import { AuthError } from 'firebase/auth';
import React, { FormEvent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

const SignIn = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState('');

  const { signIn } = useFirebaseAuth();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const email = emailRef.current!.value;
    const password = passwordRef.current!.value;
    try {
      await signIn(email, password);
    } catch (err) {
      setError((err as AuthError)?.code);
    }
  };
  return (
    <div className="grid h-screen place-items-center">
      <div className="card">
        <div className="card-body">
          <h1 className="text-xl ">登入</h1>
          <form onSubmit={handleSubmit}>
            <input
              id="email"
              type="text"
              placeholder="電子郵件或電話號碼"
              className="form-control my-2"
              ref={emailRef}
            />
            <input
              id="password"
              type="password"
              placeholder="密碼"
              className="form-control my-2"
              ref={passwordRef}
            />
            {error && <p className="text-red-500 text-xs italic">{error}</p>}
            <p className="my-2 ">
              沒有帳號?
              <Link to="/signup" className="mx-2 text-blue-400">
                註冊
              </Link>
            </p>
            <button className="button my-2">登入</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
