import React from 'react';
import NavBar from '../components/NavBar';
import { useCurrentPrize } from '../hooks/useCurrentPrize';

const Lottery = () => {
  const [currentPrize] = useCurrentPrize();
  return (
    <>
      <NavBar />
      <div className="w-screen py-6 px-4 lg:px-40">
        {currentPrize && (
          <div className="card bg-white  lg:w-96 mx-4 lg:mx-2">
            <img
              src={currentPrize.imageURL}
              alt="demoImage"
              className="w-full"
            />
            <div className="card-body">
              <p>獎項：{currentPrize.prizeName}</p>
              <p>名額：{currentPrize.quota}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Lottery;
