import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import {
  DashBoard,
  Loading,
  Lottery,
  NotFound,
  Settings,
  SignIn,
  SignUp,
} from '../pages';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user, isLoading } = useAuth();
  if (isLoading) return <Loading />;

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const GuestRoute = ({ children }: { children: JSX.Element }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) return <Loading />;

  if (user) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const routes = [
  { path: '/', authRequired: true, element: <Lottery /> },
  { path: '/dashboard', authRequired: true, element: <DashBoard /> },
  { path: '/settings', authRequired: true, element: <Settings /> },
  { path: '/login', authRequired: false, element: <SignIn /> },
  { path: '/signup', authRequired: false, element: <SignUp /> },
];

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.authRequired ? (
                <RequireAuth>{route.element}</RequireAuth>
              ) : (
                <GuestRoute>{route.element}</GuestRoute>
              )
            }
          />
        ))}
        <Route path="/NotFound" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/NotFound" />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
