import React from 'react';
import { setCurrentPrize } from '../hooks/useCurrentPrize';
import { deletePrize, Prize, updatePrize } from '../hooks/usePrize';

interface Props extends Prize {
  isCurrent?: boolean;
}

const PrizeCard = ({
  imageURL,
  prizeName,
  quota,
  id,
  isCurrent = false,
}: Props) => {
  const handleDelete = () => {
    if (!id) return;
    deletePrize(id);
  };

  const handleDraw = () => {
    setCurrentPrize({ imageURL, prizeName, quota, id });
  };

  const handleImageURLChange = (imageURL: string) => {
    updatePrize({ id, prizeName, quota, imageURL });
  };

  const handlePrizeNameChange = (prizeName: string) => {
    updatePrize({ id, prizeName, quota, imageURL });
  };

  const handleQuotaChange = (quotaString: string) => {
    const quota = parseInt(quotaString);
    updatePrize({ id, prizeName, quota, imageURL });
  };

  return (
    <div className={isCurrent ? 'card bg-orange-300' : 'card bg-white'}>
      <img src={imageURL} alt="demoImage" className="w-full" />
      <div className="card-body">
        <input
          id="imageURL"
          type="text"
          value={imageURL}
          onChange={(e) => handleImageURLChange(e.target.value)}
          placeholder="imageURL"
          className="form-control my-2"
        />
        <input
          id="prizeName"
          type="text"
          value={prizeName}
          onChange={(e) => handlePrizeNameChange(e.target.value)}
          placeholder="prizeName"
          className="form-control my-2"
        />
        <input
          id="quota"
          type="number"
          value={quota}
          onChange={(e) => handleQuotaChange(e.target.value)}
          placeholder="quota"
          className="form-control my-2"
        />
        <button className="button mx-2" onClick={handleDelete}>
          delete
        </button>
        <button className="button mx-2 " onClick={handleDraw}>
          draw
        </button>
      </div>
    </div>
  );
};

export default PrizeCard;
