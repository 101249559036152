import { User } from 'firebase/auth';
import { createContext, useContext } from 'react';

interface IAuthContext {
  user: User | null;
  isLoading: boolean;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  isLoading: false,
});

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
