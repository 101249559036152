import { Prize } from './usePrize';
import { getDatabase, ref, set } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';

export interface CurrentPrize {
  id?: string;
  imageURL: string;
  prizeName: string;
  quota: number;
}

const database = getDatabase();
const currentPrizeRef = ref(database, 'currentPrize');

export const useCurrentPrize = () =>
  useObjectVal<CurrentPrize>(currentPrizeRef);

export const setCurrentPrize = (prize: Prize) => {
  set(currentPrizeRef, prize);
};
