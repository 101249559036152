import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { auth } from '../utils/firebaseSDK';

const useFirebaseAuth = () => {
  const signUpFirebase = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signInFirebase = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signOutFirebase = () => {
    return signOut(auth);
  };
  return {
    signUp: signUpFirebase,
    signIn: signInFirebase,
    signOut: signOutFirebase,
  };
};

export default useFirebaseAuth;
